define("discourse/plugins/discourse-cartalk/discourse/components/cartalk-house-ad", ["exports", "@ember/component", "@ember/object/computed", "@ember/template", "@ember-decorators/component", "@ember-decorators/object", "rsvp", "discourse/lib/ajax", "discourse/lib/computed", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _template, _component2, _object, _rsvp, _ajax, _computed2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.adHtml}}
    {{#if this.site.mobileView}}
      <div class="house-ad-label" style="width: 300px;">
        <h2>
          ADVERTISEMENT
        </h2>
      </div>
    {{else}}
      <div class="house-ad-label"><h2>ADVERTISEMENT</h2></div>
    {{/if}}
    <div id={{this.divId}} class="house-ad-unit">{{this.adHtml}}</div>
  {{/if}}
  */
  {
    "id": "B10IFC6D",
    "block": "[[[41,[30,0,[\"adHtml\"]],[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"    \"],[10,0],[14,0,\"house-ad-label\"],[14,5,\"width: 300px;\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,\"\\n        ADVERTISEMENT\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"house-ad-label\"],[12],[10,\"h2\"],[12],[1,\"ADVERTISEMENT\"],[13],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[15,1,[30,0,[\"divId\"]]],[14,0,\"house-ad-unit\"],[12],[1,[30,0,[\"adHtml\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"h2\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/components/cartalk-house-ad.hbs",
    "isStrictMode": false
  });
  let _loaded = false,
    _promise = null,
    _currentTopicId = null,
    _adImpressionCount = 0,
    _houseAds = {};
  function loadHouseAds(siteSettings, topicId) {
    if (!_promise && topicId !== _currentTopicId) {
      _loaded = false; // Check for updated ads on each topic
      _currentTopicId = topicId;
    }
    if (_loaded) {
      return _rsvp.Promise.resolve();
    }
    if (_promise) {
      return _promise;
    }
    if (siteSettings.cartalk_ads_1_after_nth_post && siteSettings.cartalk_ads_1_after_nth_post > 0 || siteSettings.cartalk_ads_2_after_nth_post && siteSettings.cartalk_ads_2_after_nth_post > 0) {
      _promise = (0, _ajax.ajax)("/cartalk/house_ads.json").then(d => {
        _houseAds.set1 = d.house_ads_1;
        _houseAds.set2 = d.house_ads_2;
        _promise = null;
        _loaded = true;
      });
      return _promise;
    } else {
      _houseAds = {}; // house ads were turned off
      return _rsvp.Promise.resolve();
    }
  }
  const CartalkHouseAd = dt7948.c(class CartalkHouseAd extends _component.default {
    loadedAds = false;
    houseAds = {};
    static #_ = (() => dt7948.g(this.prototype, "numHouseAds", [(0, _computed.reads)("houseAdsList.length")]))();
    #numHouseAds = (() => (dt7948.i(this, "numHouseAds"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "divId", [(0, _computed2.fmt)("_adImpressionCount", "div-cartalk-housead-%@")]))();
    #divId = (() => (dt7948.i(this, "divId"), void 0))();
    houseAdsList(houseAds, setNum) {
      return this.get(`houseAds.set${setNum}`);
    }
    static #_3 = (() => dt7948.n(this.prototype, "houseAdsList", [(0, _decorators.default)("houseAds", "setNum")]))();
    adUnitClass() {
      return "cartalk-house-ad-post-bottom";
    }
    static #_4 = (() => dt7948.n(this.prototype, "adUnitClass", [_decorators.default]))();
    adHtml(loadedAds, postNumber, houseAdsList) {
      if (loadedAds) {
        const ad = houseAdsList[_adImpressionCount % this.numHouseAds];
        if (ad && ad.html) {
          return (0, _template.htmlSafe)(ad.html);
        } else {
          return null;
        }
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "adHtml", [(0, _decorators.default)("loadedAds", "postNumber", "houseAdsList")]))();
    _initCartalkHouseAds() {
      if (_currentTopicId !== this.topicId) {
        this.set("loadedAds", false);
        _adImpressionCount += 1;
      }
      loadHouseAds(this.siteSettings, this.topicId).then(() => {
        this.setProperties({
          houseAds: _houseAds,
          loadedAds: true
        });
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "_initCartalkHouseAds", [(0, _object.on)("didInsertElement")]))();
  }, [(0, _component2.classNames)("cartalk-house-ad"), (0, _component2.classNameBindings)("adUnitClass")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CartalkHouseAd);
});