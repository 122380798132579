define("discourse/plugins/discourse-cartalk/discourse/components/cartalk-make-model", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "@ember-decorators/object", "jquery", "discourse/lib/decorators", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _object, _computed, _component2, _object2, _jquery, _decorators, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.mobileView}}
    {{d-button translatedLabel="Make & Model" action=(action "togglePopup")}}
  {{else}}
    <a href {{action "togglePopup"}}>Make & Model</a>
  {{/if}}
  
  {{#if this.showPopup}}
    <div class="cartalk-mm-popup">
      {{combo-box
        name="cartalk-make"
        value=this.makeSelected
        content=this.makesForSelect
        optionValuePath="content.id"
        optionLabelPath="content.name"
        none="cartalk.choose_make"
      }}
  
      <br />
      <br />
  
      {{combo-box
        name="cartalk-make"
        value=this.modelSelected
        content=this.modelsForSelect
        optionValuePath="content.id"
        optionLabelPath="content.name"
        none="cartalk.choose_model"
        options=(hash disabled=this.modelsEmpty)
      }}
    </div>
  {{/if}}
  */
  {
    "id": "GCoaJggY",
    "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"translatedLabel\",\"action\"],[\"Make & Model\",[28,[37,2],[[30,0],\"togglePopup\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[24,6,\"\"],[4,[38,2],[[30,0],\"togglePopup\"],null],[12],[1,\"Make & Model\"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"showPopup\"]],[[[1,\"  \"],[10,0],[14,0,\"cartalk-mm-popup\"],[12],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\"],[\"cartalk-make\",[30,0,[\"makeSelected\"]],[30,0,[\"makesForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_make\"]]]],[1,\"\\n\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n\\n    \"],[1,[28,[35,5],null,[[\"name\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"none\",\"options\"],[\"cartalk-make\",[30,0,[\"modelSelected\"]],[30,0,[\"modelsForSelect\"]],\"content.id\",\"content.name\",\"cartalk.choose_model\",[28,[37,7],null,[[\"disabled\"],[[30,0,[\"modelsEmpty\"]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"a\",\"div\",\"combo-box\",\"br\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-cartalk/discourse/components/cartalk-make-model.hbs",
    "isStrictMode": false
  });
  const CartalkMakeModel = dt7948.c(class CartalkMakeModel extends _component.default {
    title = "Filter by vehicle make and model";
    layoutName = "templates/components/cartalk-make-model";
    readyToShow = false;
    showPopup = false;
    makesGroup = null;
    modelsGroups = {};
    makeSelected = null;
    modelSelected = null;
    static #_ = (() => dt7948.g(this.prototype, "modelsEmpty", [(0, _computed.empty)("modelsForSelect")]))();
    #modelsEmpty = (() => (dt7948.i(this, "modelsEmpty"), void 0))();
    init() {
      super.init(...arguments);
      this.store.findAll("cartalk/makeModelTagGroup").then(groups => {
        this.set("makesGroup", groups.find(item => item.name === "Makes"));
        groups.forEach(item => {
          if (item.name.indexOf("Makes-") === 0) {
            this.modelsGroups[item.name.substring(6).toLowerCase()] = item;
          }
        });
        this.set("readyToShow", true);
      });
    }
    makesForSelect(makesGroup) {
      return makesGroup.get("tag_names").map(tagName => {
        return {
          id: tagName,
          name: tagName
        };
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "makesForSelect", [(0, _decorators.default)("makesGroup")]))();
    modelsForSelect(makeSelected) {
      if (makeSelected && this.modelsGroups[makeSelected]) {
        return this.modelsGroups[makeSelected].tag_names.map(tagName => {
          return {
            id: tagName,
            name: tagName
          };
        });
      } else {
        return [];
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "modelsForSelect", [(0, _decorators.default)("makeSelected")]))();
    _filterByMake() {
      if (this.makeSelected && this.modelsEmpty) {
        // Some makes have no models, like Hummer. Show filtered results immediately.
        _url.default.routeTo(`/tag/${this.makeSelected}`);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "_filterByMake", [(0, _object2.observes)("modelsForSelect")]))();
    _filterByModel() {
      if (this.modelSelected) {
        _url.default.routeTo(`/tag/${this.modelSelected}`);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "_filterByModel", [(0, _object2.observes)("modelSelected")]))();
    didInsertElement() {
      super.didInsertElement(...arguments);
      const $html = (0, _jquery.default)("html");
      $html.on("mousedown.outside-cartalk-mm", e => {
        if ((0, _jquery.default)(this.element).has(e.target).length !== 0) {
          return;
        }
        this.set("showPopup", false);
        return true;
      });
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.set("showPopup", false);
      (0, _jquery.default)("html").off("mousedown.outside-cartalk-mm");
    }
    togglePopup() {
      if (this.readyToShow) {
        this.toggleProperty("showPopup");
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "togglePopup", [_object.action]))();
  }, [(0, _component2.classNameBindings)(":cartalk-mm-nav-item", "showPopup:active")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CartalkMakeModel);
});