define("discourse/plugins/discourse-cartalk/discourse/controllers/admin-plugins-cartalk", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax"], function (_exports, _controller, _object, _computed, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsCartalkController extends _controller.default {
    showHtml = true;
    selected = "digests";
    static #_ = (() => dt7948.g(this.prototype, "showingDigests", [(0, _computed.equal)("selected", "digests")]))();
    #showingDigests = (() => (dt7948.i(this, "showingDigests"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "showingHouseAds1", [(0, _computed.equal)("selected", "house_ads_1")]))();
    #showingHouseAds1 = (() => (dt7948.i(this, "showingHouseAds1"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "showingHouseAds2", [(0, _computed.equal)("selected", "house_ads_2")]))();
    #showingHouseAds2 = (() => (dt7948.i(this, "showingHouseAds2"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showingHouseAds", [(0, _computed.or)("showingHouseAds1", "showingHouseAds2")]))();
    #showingHouseAds = (() => (dt7948.i(this, "showingHouseAds"), void 0))();
    loadingAds = true;
    adHtml(set, num) {
      return (this[`model.house_ads_${set}`][num] || {
        html: ""
      }).html;
    }
    showDigests() {
      this.set("selected", "digests");
    }
    static #_5 = (() => dt7948.n(this.prototype, "showDigests", [_object.action]))();
    showHouseAds1() {
      this.set("selected", "house_ads_1");
      for (let i = 0; i < 5; i++) {
        this.set(`adUnit${i}`, this.adHtml(1, i));
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "showHouseAds1", [_object.action]))();
    showHouseAds2() {
      this.set("selected", "house_ads_2");
      for (let i = 0; i < 5; i++) {
        this.set(`adUnit${i}`, this.adHtml(2, i));
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "showHouseAds2", [_object.action]))();
    refreshDigest() {
      this.set("loading", true);
      (0, _ajax.ajax)("/admin/plugins/cartalk/preview_make_model", {
        type: "GET",
        data: {
          username: this.username,
          last_seen_at: this.lastSeen
        }
      }).then(email => {
        this.setProperties({
          html_content: email.html_content,
          text_content: email.text_content
        });
      }).finally(() => this.set("loading", false));
    }
    static #_8 = (() => dt7948.n(this.prototype, "refreshDigest", [_object.action]))();
    toggleShowHtml() {
      this.toggleProperty("showHtml");
    }
    static #_9 = (() => dt7948.n(this.prototype, "toggleShowHtml", [_object.action]))();
    saveHouseAd(id) {
      (0, _ajax.ajax)(`/admin/plugins/cartalk/house_ads/${id}`, {
        type: "PUT",
        data: {
          html: this.get(`adUnit${id}`),
          set: this.showingHouseAds1 ? "1" : "2"
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "saveHouseAd", [_object.action]))();
    updateUsername(selected) {
      this.set("username", selected.firstObject);
    }
    static #_11 = (() => dt7948.n(this.prototype, "updateUsername", [_object.action]))();
  }
  _exports.default = AdminPluginsCartalkController;
});